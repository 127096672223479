<template>
    <div class="item" v-if="mics.length > 0">
      <div class="Header">
        {{ dayOfWeek }}
      </div>
      <div>
        <div
        class="list-item"
        v-bind:key="`${dayOfWeek}-mic-${index}`" 
        v-for="({startTime, endTime }, index) in mics">
          <div>
            {{ startTime }} - {{ endTime }}
          </div>
          <a class="link" :href="`https://flophouseforms.com/OpenMic/${dayOfWeek}-${startTime.replace(' ', '-')}`">
            sign up here
          </a>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'MicItem',
    props: {
       dayOfWeek: String,
       mics: Array
    }
  }
</script>  
<style scoped>
.list-item {
  display: flex;
}
.item {
  text-align: center;
}
.link {
  color: #f72ff4;
  padding-left: 10px;
}
.Header {
  font-size: larger;
  font-weight: 500;
}
</style>