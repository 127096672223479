export const getImage = async (id, endTime) =>  {
    if(!localStorage.getItem(id)) {
        const resp = await fetch(`https://flop-house.s3.us-east-2.amazonaws.com/${id}`);
        if(!resp.ok) return "placeholder.png";
        const blob = await resp.blob();
        const text = await blob.text();
        try {
            localStorage.setItem(id, JSON.stringify({img: text, endTime}));
        } catch (e) {
            return text;
        }
    }
    return JSON.parse(localStorage.getItem(id)).img;
};