export const getComicImage = async (db, imageId) =>  {
    try {
        let image = await db?.getImage(imageId);
        if(!image) {
            const resp = await fetch(`https://comic-pics.s3.us-east-2.amazonaws.com/${imageId}`);
            if(!resp.ok) return "placeholder.png";
            const blob = await resp.blob();
            let image = { imageId, imageBlob: (await blob.text()).replaceAll('"', '')};
            await db?.addImage(image);
            return image.imageBlob;
        }
        return image.imageBlob;
    } catch (error) {
        return null;
    }

};