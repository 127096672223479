<template>
    <div class="background-header" />
    <div 
      :class="`about-container${isMobleDevice ? '-mobile' : ''}`"
      role="img"
      aria-label="Croud of people laughing."
    >
      <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
        <div class="title" tabindex="0">
          Social House Cafe
        </div>
        <div :class="`text-container${isMobleDevice ? '-mobile' : ''}`">
            <div class="sub-title" tabindex="0">
                Hours Open?
            </div>
            <div tabindex="0">
                Wednesday: 9:00am - 3:00pm
            </div>
            <div tabindex="0">
                Thursday: 9:00am - 3:00pm
            </div>
            <div tabindex="0">
                Friday: 9:00am - 2:00pm and Food Service 6:00pm-9:00pm
            </div>
            <div tabindex="0">
                Saterday: 9:00am - 2:00pm and Food Service 6:00pm-9:00pm
            </div>
            <br>
          <n-carousel autoplay show-arrow :class="`carousel-container${isMobleDevice ? '-mobile' : ''}`">
            <template v-for=" (image) in images" :key="image">
              <img
                class="carousel-img"
                alt="Image of Flop House Comedy"
                :src="image"
              >
            </template>
            </n-carousel>

        </div>
      </div>
    </div>
    <footerbar/>
  </template>
  
<script>
  import Footerbar from '../components/Footerbar.vue';
  import { NCarousel } from 'naive-ui'
    export default {
      name: 'CafePage',
      components: {
        Footerbar,
        NCarousel
      },
      data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const images = [];
        for(let i = 1; i < 6; i++) {
          images.push(require(`../assets/cafe/${i}.jpg`));
        }
        return {
            isMobleDevice,
            images
        }
      },
    }
  </script>
  
  <style scoped>
  .link {
    color: #f72ff4;
  }
  .requirement-list {
    margin-top: 0;
    text-align: left;
  }
  .title {
    text-align: center;
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .sub-title {
    text-align: center;
    font-size: x-large;
    font-weight: 500;
  }
  .inner-about-container {
    text-align: center;
    width: 900px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
  }
  .about-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .about-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  .carousel-container {
    height: 600px;
  }
  .carousel-container-mobile {
    height: 200px;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>