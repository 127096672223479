<template>
    <div class="navbar-container">
        <n-page-header>
            <template #title v-if="startNav !== '/'">
                <div class="img-container">
                    <img src="../assets/FlopHouseCard.png" alt="TestImage" width="160" height="130">
                </div>
            </template>
            <template #extra>
                <n-menu 
                    v-if="!isMobleDevice"
                    class="menu"
                    :style="!isMobleDevice && startNav === '/' ? 'margin-top: 43px;' : ''"
                    mode="horizontal"
                    :theme-overrides="themeOverrides"
                    :value="startNav" 
                    :options="menuOptions" 
                    tabindex="0"
                />
                <n-dropdown
                    v-if="isMobleDevice"
                    class="drop-down"
                    placement="bottom-start"
                    trigger="click"
                    size="huge"
                    :theme-overrides="themeOverridesMobile"
                    style="background-color: rgba(0, 0, 0, 0.8); color: white;"
                    :options="menuOptions"
                    >
                    <n-button class="menu-button" text-color="white" :style="startNav !== '/' ? 'margin-bottom: 43px;' : ''" color="rgba(0, 0, 0, 0.8)">
                        <font-awesome-icon
                            class="menu-icon"
                            :icon="['fas', 'fa-bars']" 
                        />    
                    </n-button>
                    </n-dropdown>
            </template>
        </n-page-header>
    </div>
</template>
  
<script>
import { NPageHeader } from 'naive-ui'
import { NMenu } from 'naive-ui'
import { NDropdown } from 'naive-ui'
import { NButton } from 'naive-ui'
import { RouterLink } from 'vue-router'
import { h } from 'vue'
export default {
    name: 'NavbarComponent',
    components: {
        NPageHeader,
        NMenu,
        NDropdown,
        NButton
    },
    watch:{
        $route (){
            this.startNav = window.location.pathname.includes('comedians') ? '/comedians' : window.location.pathname;
        }
    },
    data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return {
            isMobleDevice,
            startNav: window.location.pathname.includes('comedians') ? '/comedians' : window.location.pathname,
            themeOverridesMobile: {
                optionTextColor: "white"
            },
            themeOverrides: {
                itemTextColorActiveHorizontal: "white",
                itemTextColorHoverHorizontal: "#f72ff4",
                itemTextColorActiveHoverHorizontal: "#f72ff4",
                itemTextColorHorizontal: "rgb(150, 150, 150)"
            },
            menuOptions: [
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/"},
                        { default: () => 'Home' }
                    ),
                    key: '/',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/calendar"},
                        { default: () => 'Calendar' }
                    ),
                    key: '/calendar',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/comedians"},
                        { default: () => 'Comedians' }
                    ),
                    key: '/comedians',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/events"},
                        { default: () => 'Rent Space' }
                    ),
                    key: '/events',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/cafe"},
                        { default: () => 'Cafe' }
                    ),
                    key: '/cafe',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/openmics"},
                        { default: () => 'Open Mics' }
                    ),
                    key: '/openmics',
                },
                {
                    label: () =>
                    h(RouterLink, 
                        {to: "/about"},
                        { default: () => 'About' }
                    ),
                    key: '/about',
                },
            ]
        }
    }
}
</script>
  
<style scoped>
    .menu-button {
        margin-right: 10px;
    }
    .menu-icon{
        font-size: x-large;
    }
    .menu {
        font-family: system-ui;
        font-size: large;
        font-weight: 500;
        background: rgba(0, 0, 0, 0.8);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .navbar-container {
        position: absolute;
        width: 100%;
        top: 10px;
        z-index: 2;
    }
    .img-container {
        margin-top: -10px;
    }
</style>