import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/Home.vue";
import AboutPage from "@/views/About.vue";
import EventsPage from "@/views/Events.vue";
import DayEventsPage from "@/views/DayEvents.vue";
import ComediansPage from "@/views/Comedians.vue";
import ComedianPage from "@/views/Comedian.vue";
import OpenMicsPage from "@/views/OpenMics.vue";
import CafePage from "@/views/Cafe.vue";

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/about",
    name: "About",
    component: AboutPage,
  },
  {
    path: "/events",
    name: "Events",
    component: DayEventsPage,
  },
  {
    path: "/cafe",
    name: "Cafe",
    component: CafePage,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: EventsPage,
  },
  {
    path: "/openmics",
    name: "Open Mics",
    component: OpenMicsPage,
  },
  {
    path: "/comedians",
    name: "Comedians",
    component: ComediansPage,
  },
  {
    path: "/comedians/:name",
    name: "Comedian",
    component: ComedianPage,
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if(!hasQueryParams(to) && hasQueryParams(from)) {
    next({name: to.name, query: from.query});
  } else {
    next()
  }
})

export default router;